import React, { useEffect, useState } from "react";
import data from "./PHQ9.json";
import { useLocation } from "react-router-dom";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

// Define the structure of the data in PHQ9.json
interface SuggestionData {
  QuestionNo: number;
  OptionNumber: number;
  SuggestionNumber?: number;
  Title: string;
  Description: string;
  PhotoUrl?: string;
}

// Define the structure of the answers object
interface Answers {
  [key: string]: number;
}

function Suggestions() {
  const location = useLocation();
  const { answers } = location.state || {};
  const data1: SuggestionData[] = data;
  console.log("answers", answers);
  const [selectedTile, setSelectedTile] = useState(null);
  const [finalSuggestions, setFinalSuggestions] = useState<SuggestionData[]>(
    []
  );

  const handleTileClick = (index: any) => {
    setSelectedTile(selectedTile === index ? null : index); // Toggle description
  };

  useEffect(() => {
    getSuggestions(answers);
  }, []);

  const getSuggestions = (answers: Answers) => {
    let suggestions: SuggestionData[] = [];

    // Loop through the answers object
    for (let questionNo in answers) {
      const optionNumber = answers[questionNo];

      // Find the corresponding suggestion in the data
      const suggestion = data1.find(
        (item) =>
          item.QuestionNo === Number(questionNo) &&
          item.OptionNumber === optionNumber
      );

      if (suggestion) {
        suggestions.push({
          QuestionNo: suggestion.QuestionNo,
          OptionNumber: suggestion.OptionNumber,
          SuggestionNumber: suggestion.SuggestionNumber ?? 0,
          Title: suggestion.Title,
          Description: suggestion.Description,
          PhotoUrl: suggestion.PhotoUrl ?? "",
        });
      }
    }
    setFinalSuggestions(suggestions);
    console.log("suggestions", suggestions);
    // return suggestions;
  };

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {finalSuggestions.map((item) => (
          <div
            key={item.QuestionNo}
            onClick={() => handleTileClick(item.Description)}
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              margin: "10px",
              padding: "10px",
              cursor: "pointer",
              width: "250px",
              textAlign: "center",
            }}
          >
            <LightbulbIcon
              style={{
                backgroundColor: "orange",
                fontSize: "50px",
                color: "white",
              }}
            />
            <h3>{item.Title}</h3>
            {/* <img
              src={item.PhotoUrl}
              alt={item.Title}
              style={{ width: "100%", borderRadius: "4px" }}
            /> */}
          </div>
        ))}
      </div>
      {selectedTile && (
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            margin: "20px 0",
            padding: "10px",
          }}
        >
          <h4>Description:</h4>
          <p>{selectedTile}</p>
        </div>
      )}
    </div>
  );
}

export default Suggestions;
