import React, { FC } from "react";
import Dashboard from "./pages/Dashboard";
import Questionnaire from "./pages/Questionnaire";
import Suggestions from "./pages/Suggestions";

interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
  index: number;
  //   icon: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "dashboard",
    title: "Dashboard",
    path: `${process.env.PUBLIC_URL}/dashboard`,
    enabled: true,
    component: Dashboard,
    index: 0,
  },

  {
    key: "questioniare",
    title: "Questioniare",
    path: `${process.env.PUBLIC_URL}/questioniare`,
    enabled: true,
    component: Questionnaire,
    index: 1,
  },
  {
    key: "suggestions",
    title: "Suggestions",
    path: `${process.env.PUBLIC_URL}/suggestions`,
    enabled: true,
    component: Suggestions,
    index: 0,
  },

  /* Add additional routes in Routes.ts as required following the above format: */
];
