import React from "react";
import "./App.css";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import CONSTANTS from "./constants/constants";
import store from "./redux/Store";
import { Provider } from "react-redux";
import Wrapper from "./pages/Wrapper";
import Navigation from "./Navigator";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

const App = () => {
  const getAppChildren = () => (
    <Router>
      {/* <ThemeProvider theme={INITIAL_THEME}> */}
      <Provider store={store}>
        <Wrapper>
          {/* Wrapper component is used to wrap the navigation and provide additional layout such as Header.*/}
          <Navigation />
          {/* Navigation component contains the routing structure of the application. */}
        </Wrapper>
      </Provider>
      {/* </ThemeProvider> */}
    </Router>
  );

  //ReactKeycloakProvider is being used to provide authentication capabilities.

  return <div>{getAppChildren()}</div>;
};

// RenderOnAuthenticated is a component that renders its children only when the user is authenticated.

export default App;
