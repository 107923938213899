import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void; // Function type for onClose prop
  header?: string;
  description?: string;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  header,
  description,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6">{header}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
