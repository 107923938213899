import React, { useState } from "react";
import Carousel from "./Carousel";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../components/CustomDialog";
import moment from "moment";

interface values {
  number: string;
  mail: string;
  name: string;
  dob: string;
}

const initailValue = {
  number: "",
  mail: "",
  name: "",
  dob: "",
};

function Dashboard() {
  const navigate = useNavigate();

  const [initialValues, setinitialValues] = useState(initailValue);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    number: Yup.string()
      .required("Phone Number is required")
      .length(10, "Phone Number must be 10 Digits"),
    dob: Yup.string().required("Date od Birth is required"),
    name: Yup.string().required("Name is required"),
    mail: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik<values>({
    initialValues,
    validationSchema,

    onSubmit: (values: any) => {
      console.log("Submitted values:", values);
      // CreateSlots(values);
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: any) => {
    const { name, mail, dob, number } = values;

    // Check if any of the fields (email or phone) already exist in local storage
    const savedUsers = JSON.parse(localStorage.getItem("users") || "[]");

    const userExists = savedUsers.some(
      (user: any) => user.mail === mail && user.number === number
    );

    if (userExists) {
      setOpen(true);
      setError("Already submitted test. Please take again after 3 months.");
    } else {
      // Add new user data to local storage
      const newUser = { name, number, mail, dob };
      savedUsers.push(newUser);
      localStorage.setItem("users", JSON.stringify(savedUsers));
      navigate(`${process.env.PUBLIC_URL}/questioniare`, {
        state: {
          newUser: newUser,
        },
      });
      // Clear error and reset form
      setError(null);
      setinitialValues({
        name: "",
        dob: "",
        mail: "",
        number: "",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ backgroundColor: "whitesmoke", padding: 4 }}>
      {/* {error && <Alert severity="error">{error}</Alert>} */}

      <CustomDialog
        header={"Error"}
        description={
          "Your Test already been submitted! Please Retake Test after 3 months"
        }
        open={open}
        onClose={handleClose}
      />
      <Grid
        sx={{ backgroundColor: "#ffffff", borderRadius: 2 }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={8}>
          <Carousel imageHeight="550px" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              margin: 2,
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              fullWidth
              id="number"
              name="number"
              label="Number"
              value={formik.values.number}
              inputProps={{
                maxLength: 10,
              }}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
            />
            <TextField
              fullWidth
              id="mail"
              name="mail"
              label="Mail"
              value={formik.values.mail}
              onChange={formik.handleChange}
              error={formik.touched.mail && Boolean(formik.errors.mail)}
              helperText={formik.touched.mail && formik.errors.mail}
            />
            <TextField
              fullWidth
              id="dob"
              name="dob"
              type="date"
              InputLabelProps={{ shrink: true }}
              label="Date Of Birth"
              value={formik.values.dob}
              onChange={formik.handleChange}
              error={formik.touched.dob && Boolean(formik.errors.dob)}
              helperText={formik.touched.dob && formik.errors.dob}
            />
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={formik.submitForm}
                variant="contained"
                // color=""
                // fullWidth
                sx={{ marginTop: 2, backgroundColor: "#1B4F72", width: "60%" }}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
      {/* <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",

          "& .MuiInputBase-root": {
            fontSize: "12px !important",
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px !important",
            // Adjust margin to reduce space between label and input
            marginBottom: "4px !important", // Adjust if needed
          },
          "& .MuiSelect-select": {
            fontSize: "12px !important",
          },
          "& .MuiFormControlLabel-label": {
            fontSize: "12px !important",
          },
          marginTop: "20px",
        }}
      ></Box> */}
    </Box>
  );
}

export default Dashboard;

/* Control Flow: index -> App -> Wrapper -> Header -> SideNav -> NavListItems(Dashboard) -> Dashboard */
