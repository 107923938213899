// src/Footer.js
import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        textAlign: "center",
        backgroundColor: "whitesmoke",
        mt: "auto",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        {"© "}
        {new Date().getFullYear()}{" "}
        <Link href="https://techsophy.com/" target="_blank" rel="noopener">
          Techsophy
        </Link>
        {". All rights reserved."}
      </Typography>
    </Box>
  );
};

export default Footer;
