import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CustomDialog from "../components/CustomDialog";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
// import CustomDialog from "../components/CustomDialog";
// import { useNavigate } from "react-router-dom";

import {
  AUTH_URL,
  BASE_URL,
  CLIENT_ID,
  DOCUMENT_TYPE_ID,
  REALM,
  USERNAME,
  PASSWORD,
  GRANT_TYPE,
  CLIENT_SECRET,
  TEMPLATE_ID,
  TO_MAIL,
  FROM_MAIL,
} from "../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";

interface Option {
  points: number;
  title: string;
}

interface Question {
  id: number;
  q_text: string;
  options?: Option[];
  type: string;
}

const Questionnaire: React.FC = () => {
  const location = useLocation();
  const { newUser } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [recoring, setRecoring] = useState(true);
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: string;
  }>({});
  const [open, setOpen] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [datafial, setDataFial] = useState<
    { question: string; answer: string }[]
  >([]);
  const [token, setToken] = useState("");

  const data: Question[] = [
    {
      id: 0,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text: "Little interest or pleasure in doing things?",
      type: "question",
    },
    {
      id: 1,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text: "Feeling down, depressed, or hopeless?",
      type: "question",
    },
    {
      id: 2,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text: "Trouble falling or staying asleep, or sleeping too much?",
      type: "question",
    },
    {
      id: 3,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text: "Feeling tired or having little energy?",
      type: "question",
    },
    {
      id: 4,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text: "Poor appetite or overeating?",
      type: "question",
    },
    {
      id: 5,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text:
        "Feeling bad about yourself - or that you are a failure or have let yourself or your family down?",
      type: "question",
    },
    {
      id: 6,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text:
        "Trouble concentrating on things, such as reading the newspaper or watching television?",
      type: "question",
    },
    {
      id: 7,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text:
        "Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual?",
      type: "question",
    },
    {
      id: 8,
      options: [
        {
          points: 1,
          title: "Not at all",
        },
        {
          points: 2,
          title: "Several days",
        },
        {
          points: 3,
          title: "More than half the days",
        },
        {
          points: 4,
          title: "Nearly every day",
        },
      ],
      q_text:
        "Thoughts that you would be better off dead, or of hurting yourself in some way?",
      type: "question",
    },
    {
      id: 9,
      q_text: "Please Record a Voice Note",
      type: "audio",
    },
  ];

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value; // Get the selected title instead of points
    const currentQuestionId = data[currentQuestionIndex].id;
    setSelectedAnswers({
      ...selectedAnswers,
      [currentQuestionId]: selectedValue,
    });
  };

  const handleNext = () => {
    if (currentQuestionIndex < data.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  console.log("frommail", FROM_MAIL, TEMPLATE_ID, TO_MAIL, CLIENT_ID);
  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleFinish = async () => {
    setIsLoading(true);
    const completedAnswers = data.map((question) => ({
      question: question.q_text,
      answer: selectedAnswers[question.id] || "", // Set to "" if no answer selected
    }));

    const formData = new FormData();

    formData.append("documentPath", "SPOC"); // Set your documentPath value here
    formData.append("documentName", "SPOC"); // Set your documentName value here
    formData.append("documentDescription", "");
    // If there is a recorded audio URL, convert it to a file and append it
    if (audioChunksRef.current.length > 0) {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const audioFile = new File([audioBlob], "recorded_audio.wav", {
        type: "audio/wav",
      });

      // Append the audio file to formData
      formData.append("file", audioFile);
    }
    setAnswers(selectedAnswers);
    setDataFial(completedAnswers);

    await axios
      .post(
        `${AUTH_URL}/${REALM}/protocol/openid-connect/token`,
        {
          client_id: CLIENT_ID,
          username: USERNAME,
          password: PASSWORD,
          grant_type: GRANT_TYPE,
          client_secret: CLIENT_SECRET,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setToken(res.data?.access_token);
        UploadFile(res.data?.access_token, formData, completedAnswers);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const UploadFile = async (
    token: any,
    formData: any,
    completedAnswers: any
  ) => {
    console.log("UploadFile", formData, completedAnswers);
    try {
      // Example of submitting formData using Axios

      const response = await axios.post(
        `${BASE_URL}/dms/v1/documents?documentTypeId=${DOCUMENT_TYPE_ID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `bearer ${token}`,
          },
        }
      );

      SendMail(token, response.data?.data?.id, completedAnswers);

      // setOpen(true); // Show success dialog
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  const SendMail = async (token: any, id: any, completedAnswers: any) => {
    try {
      // Example of submitting formData using Axios
      const response = await axios.post(
        `${BASE_URL}/notification/v1/graphql`,
        {
          operationName: "SEND_EMAIL",
          variables: {
            mailMessage: {
              templateId: TEMPLATE_ID,
              data: {
                username: newUser?.name,
                question1: completedAnswers[0]?.question,
                question1Answer: completedAnswers[0]?.answer,
                question2: completedAnswers[1]?.question,
                question2Answer: completedAnswers[1]?.answer,
                question3: completedAnswers[2]?.question,
                question3Answer: completedAnswers[2]?.answer,
                question4: completedAnswers[3]?.question,
                question4Answer: completedAnswers[3]?.answer,
                question5: completedAnswers[4]?.question,
                question5Answer: completedAnswers[4]?.answer,
                question6: completedAnswers[5]?.question,
                question6Answer: completedAnswers[5]?.answer,
                question7: completedAnswers[6]?.question,
                question7Answer: completedAnswers[6]?.answer,
                question8: completedAnswers[7]?.question,
                question8Answer: completedAnswers[7]?.answer,
                question9: completedAnswers[8]?.question,
                question9Answer: completedAnswers[8]?.answer,
              },
              attachments: [id],
              to: TO_MAIL,
              from: FROM_MAIL,
              subject: "Welcome to my methal Health",
            },
          },
          query:
            "mutation SEND_EMAIL($mailMessage: MailMessage!) {\n  sendMail(input: $mailMessage)\n}\n",
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      setIsLoading(false);
      setOpen(true); // Show success dialog
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const isNextDisabled =
    selectedAnswers[data[currentQuestionIndex]?.id] === undefined;

  const handleClose = () => {
    setOpen(false);
    navigate(`${process.env.PUBLIC_URL}/dashboard`);
    // navigate(`${process.env.PUBLIC_URL}/suggestions`, { state: { answers } });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setAudioURL(url);
      setAnswers({ ...answers, [data[currentQuestionIndex].id]: url });
    }
  };

  // Recording audio functionality
  const startRecording = async () => {
    setIsRecording(true);
    audioChunksRef.current = []; // Clear previous audio chunks
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
      setAnswers({ ...answers, [data[currentQuestionIndex].id]: url });
    };

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    setRecoring(false);
    setIsRecording(false);
    mediaRecorderRef.current?.stop();
  };

  const handleClearAudio = () => {
    setAudioURL(""); // Clear the audio URL
    setRecoring(true);
  };

  // Audio upload and recording functions...

  return (
    <Box
      sx={{
        width: "100%",
        // maxWidth: 600,
        marginTop: 2,
        padding: 4,
        backgroundColor: "#fff",
        borderRadius: 2,
      }}
    >
      {isLoading && (
        <Backdrop
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 2,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <CustomDialog
        header={"Success"}
        description={
          "Your Test has been submitted Successfully! Our representative will get back to you with suggestions."
        }
        open={open}
        onClose={handleClose}
      />

      {/* Question Text */}
      <Typography variant="h6" gutterBottom>
        Q{currentQuestionIndex + 1} : {data[currentQuestionIndex]?.q_text}
      </Typography>

      {/* Options */}
      {data[currentQuestionIndex]?.type === "question" && (
        <RadioGroup
          value={
            selectedAnswers[data[currentQuestionIndex]?.id] !== undefined
              ? selectedAnswers[data[currentQuestionIndex]?.id]
              : ""
          }
          onChange={handleOptionChange}
        >
          {data[currentQuestionIndex]?.options?.map((option) => (
            <FormControlLabel
              key={option.points}
              value={option.title} // Change this to option.title
              control={<Radio />}
              label={option.title}
            />
          ))}
        </RadioGroup>
      )}

      {/* Audio Upload */}
      {data[currentQuestionIndex]?.type === "audio" && (
        <Box>
          <Button
            variant="contained"
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? "Stop Recording" : "Start Recording"}
          </Button>
          {/* {/* <input type="file" accept="audio/*" onChange={handleFileChange} /> */}
          <br />
          <br />
          {/* {audioURL && <audio controls src={audioURL} />} */}
          {audioURL && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <audio controls src={audioURL} />
              <IconButton onClick={handleClearAudio} aria-label="clear audio">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </Box>
      )}

      {/* Navigation Buttons */}
      <Box
        sx={{ display: "flex", justifyContent: "space-between", marginTop: 4 }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={currentQuestionIndex === 0}
          onClick={handleBack}
        >
          Back
        </Button>
        {currentQuestionIndex === data.length - 1 ? (
          <Button
            disabled={recoring}
            variant="contained"
            color="primary"
            onClick={handleFinish}
          >
            Finish
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={isNextDisabled}
            onClick={handleNext}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Questionnaire;
