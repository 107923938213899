const CONSTANTS = {
  WELCOME_MSG: "welcome_msg",
  COMPONENT_LIST_MSG: "component_list_msg",
  DEFAULT_MSG: "default_msg",
  PROFILE_MSG: "profile_msg",
  SETTINGS: "settings",
  PROFILE: "profile",
  LOGOUT: "logout",
  THEME: "theme",
  SXP_TITLE: "Welcome to TSF React base project",
  SXP_SUBTITLE: "Service eXperience Platform",
  SXP_DEFAULT_MSG: "Hi, Welcome To React Template Project",
  VERSION_ID: "WORKING",
  COULDNT_UPLOAD_DOC: "Could not upload Document",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  BUSINESS_USER: "business-user",
  SELECT_START_END_DATE: "Select start date and end date",
  USER_EMAIL: "user-email",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  USER_ID: "user-id",
  REACT_TOKEN: "react-token",
  USER_TYPE: "user-type",
  COUNTRY: "country",
  I18NEXTLNG: "i18nextLng",
  BASEURL: "https://api-dev.techsophy.com/api",
};

export default CONSTANTS;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";
export const USERNAME = process.env.REACT_APP_USERNAME || "";
export const PASSWORD = process.env.REACT_APP_PASSWORD || "";
export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE || "";
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || "";
export const DOCUMENT_TYPE_ID = process.env.REACT_APP_DOCUMENT_TYPE_ID || "";
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const AUTH_URL = process.env.REACT_APP_AUTH_URL || "";
export const REALM = process.env.REACT_APP_REALM || "";
export const FROM_MAIL = process.env.REACT_APP_FROM_MAIL || "";
export const TO_MAIL = process.env.REACT_APP_TO_MAIL || "";
