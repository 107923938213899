import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./ImageCarousel.css"; // Import the CSS file for animations
import Happy from "../assets/images/Happy.png";
import HowAreYou from "../assets/images/HowAreYou.png";

// Define the props for image height configuration
interface ImageCarouselProps {
  imageHeight: string | number; // Accept height as a prop (px, %, etc.)
}

const images = [
  {
    src: Happy,
    text: "First Image Description",
  },
  {
    src: HowAreYou,
    text: "Second Image Description",
  },
];

const ImageCarousel: React.FC<ImageCarouselProps> = ({ imageHeight }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("next");

  // Auto-scroll logic
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       handleNext();
  //     }, 3000); // Auto-scroll every 3 seconds

  //     return () => clearInterval(interval);
  //   }, [currentIndex]);

  const handleNext = () => {
    setDirection("next");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setDirection("prev");
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid container alignItems="center">
        <TransitionGroup component={null}>
          <CSSTransition
            key={currentIndex}
            classNames={direction === "next" ? "slide-left" : "slide-right"}
            timeout={500}
          >
            {/* Image Section */}
            <Grid item xs={12} md={12}>
              <img
                src={images[currentIndex].src}
                alt={`carousel-${currentIndex}`}
                style={{
                  width: "100%",
                  height: imageHeight,
                  //   borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </Grid>
          </CSSTransition>
        </TransitionGroup>
      </Grid>
    </Box>
  );
};

export default ImageCarousel;
